body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body, h1, h2, h3, h4, h5 {
  font-family: "Poppins", sans-serif;
  color:#0D1B2A
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
  margin: 10px 0;
}

html, body {
  font-size: 16px;
  line-height: 1.5;
}

h1 {
  font-size: 64px!important;
  font-weight: bolder;
}

h2 {
  color: #415A77!important;
  font-size: 48px!important;
  font-weight: bolder;
}
.h2Line{
  width: 50px;
  border: 5px solid #415A77;
  text-align: left;
  border-radius: 4px;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  color: #415A77!important;
  box-sizing: inherit;
  text-align: left;
  margin: 0;
}


/*
#0D1B2A
#1B263B
#415A77
#778DA9
#E0E1DD
*/